import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import OASection from "../components/our-approach-section";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const WebDevelopmentPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			gatsbyImg: wpMediaItem(title: { eq: "WEB-DEVELOPMENT-GATSBYJS" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ellaImg: wpMediaItem(title: { eq: "Ellaroberta-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			facialImg: wpMediaItem(title: { eq: "Facialrehab-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			javaImg: wpMediaItem(title: { eq: "javascript-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			gatsbyIconImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Web Development" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpWebDevelopmentPage {
				webDevelopmentPageFields {
					benefitsSection {
						title
						benefit1 {
							description
							title
						}
						benefit2 {
							description
							title
						}
						benefit3 {
							description
							title
						}
					}
					bookAMeetingSection {
						title
						description
					}
					fourStepSection {
						title
						button {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						step1 {
							title
							description
						}
						step2 {
							description
							title
						}
						step3 {
							description
							title
						}
						step4 {
							description
							title
						}
					}
					heroSection {
						button1 {
							text
							link
						}
						description
						title
					}
					jamstackSection {
						button {
							link
							text
						}
						content
						description
						image1 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						title
						smallTitle
						image2 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image3 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					whoWeWorkWithSection {
						description
						title
						image1 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image2 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image3 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					whyWeUseSection {
						title
						description
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const {
		wpWebDevelopmentPage: {
			webDevelopmentPageFields: {
				benefitsSection,
				bookAMeetingSection,
				fourStepSection,
				heroSection,
				jamstackSection,
				whoWeWorkWithSection,
				whyWeUseSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Web Development",
				item: {
					url: `${siteUrl}/web-development`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/web-development`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#jamstack"
								>
									The JAMstack
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-we-use"
								>
									What we use
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center">
							<Col lg={8} className=" text-lg-center">
								<p className=" ssp-bold text-uppercase text-light-blue fs-5 mb-3">
									{jamstackSection.smallTitle}
								</p>
								<div>{parse(jamstackSection.title)}</div>
								<div>{parse(jamstackSection.description)}</div>
								<Button
									className="cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									as={Link}
									to={jamstackSection.button.link}
								>
									{jamstackSection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="jamstack" className=" bg-white py-5 py-lg-7">
					<Container>
						<Row className=" align-items-center justify-content-between gx-7">
							<Col lg={7} className=" ">
								<div>{parse(jamstackSection.content)}</div>
							</Col>

							<Col lg={4} className="position-relative d-none d-lg-block">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										overflow: "hidden",
									}}
									className="bg-white ms-auto  d-flex align-items-center"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={jamstackSection.image1.gatsbyImage}
										alt={jamstackSection.image1.altText}
									/>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-4rem",
										overflow: "hidden",
									}}
									className=" me-auto position-relative "
								>
									<GatsbyImage
										className="w-100 h-100"
										image={jamstackSection.image2.gatsbyImage}
										alt={jamstackSection.image2.altText}
									/>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 99px #00000026",
										width: "15rem",
										height: "15rem",
										top: "-8rem",
										overflow: "hidden",
										backgroundColor: "#663399",
									}}
									className=" ms-auto position-relative"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={jamstackSection.image3.gatsbyImage}
										alt={jamstackSection.image3.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container>
						<Row>
							<Col lg={6}>
								<div>{parse(benefitsSection.title)}</div>
							</Col>
						</Row>
						<div style={{ height: "100px" }}></div>

						<Row className="g-6">
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										1
									</p>
									<p className="text-white position-relative fs-1 ssp-bold">
										{benefitsSection.benefit1.title}
									</p>
									<p className="text-white position-relative">
										{benefitsSection.benefit1.description}
									</p>
								</div>
							</Col>
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										2
									</p>
									<p className="text-white position-relative fs-1 ssp-bold">
										{benefitsSection.benefit2.title}
									</p>
									<p className="text-white position-relative">
										{benefitsSection.benefit2.description}
									</p>
								</div>
							</Col>
							<Col lg={4}>
								<div className="position-relative h-100">
									<p
										className="text-light-blue position-absolute translate-middle"
										style={{
											fontSize: "330px",
											top: "20%",
											left: "75%",
											opacity: "10%",
										}}
									>
										3
									</p>
									<p className="text-white position-relative fs-1 ssp-bold">
										{benefitsSection.benefit3.title}
									</p>
									<p className="text-white position-relative">
										{benefitsSection.benefit3.description}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="what-we-use" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center">
							<Col lg={6} className=" ">
								<div>{parse(whyWeUseSection.title)}</div>
								<div>{parse(whyWeUseSection.description)}</div>
								{/* <Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									as={Link}
									to="/about-us"
								>
									View our agency page
								</Button> */}
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={whyWeUseSection.image.gatsbyImage}
										alt={whyWeUseSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey position-relative py-5 py-lg-7">
					<div className="position-relative bg-med-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center ">
								<Col lg={6} className=" ">
									<div>{parse(whoWeWorkWithSection.title)}</div>
									<div>{parse(whoWeWorkWithSection.description)}</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div style={{ top: "-5rem" }} className="position-relative">
						<Container>
							<Row className="g-6">
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 height-limit"
											image={whoWeWorkWithSection.image1.gatsbyImage}
											alt={whoWeWorkWithSection.image1.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 "
											image={whoWeWorkWithSection.image2.gatsbyImage}
											alt={whoWeWorkWithSection.image2.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className=""
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 height-limit"
											image={whoWeWorkWithSection.image3.gatsbyImage}
											alt={whoWeWorkWithSection.image3.altText}
											objectPosition="top center"
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<div id="approach">
					<OASection
						imgHeight="60rem"
						customHeading
						heading={fourStepSection.title}
						image={fourStepSection.image.gatsbyImage}
						imageAlt={fourStepSection.image.altText}
						heading1={fourStepSection.step1.title}
						heading2={fourStepSection.step2.title}
						heading3={fourStepSection.step3.title}
						heading4={fourStepSection.step4.title}
						launch={fourStepSection.step4.description}
						build={fourStepSection.step3.description}
						design={fourStepSection.step2.description}
						brief={fourStepSection.step1.description}
						buttonUrl={fourStepSection.button.link}
						buttonText={fourStepSection.button.text}
					/>
				</div>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				{/* <section>
					<TeamSection />
				</section> */}
				{/* <section className="bg-light-grey pt-5 pb-7 py-lg-8">
					<Container>
						<Row className="align-items-centergx-lg-6 gx-xl-7  g-6">
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000001D ",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className=" ">
								<h2 className=" text-primary mb-5">Pricing</h2>
								<p>
									Our web development provides you with a custom built website
									that meets the bespoke needs of your business. Contact us for
									a free consultation, where we can propose a solution and
									provide you with a custom quote.
								</p>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									CONTACT US FOR A QUOTE
								</Button>
							</Col>
						</Row>
					</Container>
				</section> */}
				<Logos />

				<div id="faq">
					<Faq page="Web Development" />
				</div>
				{/* <OSSection
					link3="/branding"
					text3="BRANDING"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default WebDevelopmentPage;
